// eslint-disable-next-line consistent-return
export default defineNuxtRouteMiddleware((to) => {
  const { blackWeekEnabledLocales } = useRuntimeConfig().public.featureFlags;

  const locale = to.fullPath.split('/')[1];

  if (!blackWeekEnabledLocales.includes(locale)) {
    return navigateTo('/');
  }
});
